import React from "react";
import './Autenticacao.css';
import Form from "react-bootstrap/esm/Form";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { Button, Container, Modal } from "react-bootstrap";
import { useState } from 'react';
import axios from "axios";
import SVG from 'react-inlinesvg';
import Swal from "sweetalert2";
import Api from '../../utils/Api';


const Autenticacao = () => {
    const apiNova = new Api;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [code, setCode] = useState('');
    const [qrcode, setQrcode] = useState('');
    const [urlApi, setUrlApi] = useState(apiNova.getUrlApi());

    const handleCode = (event: any) => {
        event.preventDefault();
        setCode(event.target.value);
        console.log(code);
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
       
        if(code === undefined || code === "" || code === null){
            Swal.fire(
                'Erro ao gerar QrCode',
                'Preencha o campo de codigo!',
                'error'
            )
            return;
        }
        axios(`${urlApi}checkout/${code}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                code: code
            })
        }).then((response) => {
            setQrcode(response.data);
            setShow(true);
            setInterval(() => {
                setShow(false);
            }, 200000)
        }).catch((error) => {
            Swal.fire(
                'Erro ao gerar QrCode',
                'Aguarde 10 segundos e tente novamente',
                'error'
            )
        })

    }


    return (
        <>
                <Container className="container__ajuste">
                <img className="imagemformulario" src="/imagens/logokentro.png" />
                <Form className="formulario" onSubmit={handleSubmit}>
                    <CardHeader>
                   
                        <p><label>Adicione o Codigo: </label></p>
                        <input type="text" id="inputcode" className="inputCode" onChange={handleCode} /><br /><Button type="submit" className="btn btn-warning buttonQrCode" >Gerar QrCode</Button>
                    </CardHeader>
                </Form>
                </Container>
                <Modal className="modal-xl" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Realize a leitura do QRCode</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modalExtra"><SVG className="codeQr" src={qrcode} /></Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={handleClose}>
                            Concluir
                        </Button>
                    </Modal.Footer>
                </Modal>
            
        </>
    )
}

export default Autenticacao;