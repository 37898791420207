import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import BarraPrincipal from '../../components/Navbar/Navbar';
import axios from 'axios';
import { useEffect, useState } from 'react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Panel.css';
import { FiCopy, FiRotateCw, FiSettings, FiStopCircle, FiUpload } from "react-icons/fi";
import copy from "copy-to-clipboard";
import { Alert, Card, Form, Pagination } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Api from '../../utils/Api';

export default function Panel() {

  //DADOS PARA API
  const novaApi = new Api;
  const [urlApi, setUrlApi] = useState(novaApi.getUrlApi());
  
  const [acao,setAcao] = useState(false);
  
  
  const [canais, setCanais] = useState([]);
  const [pagina, setPagina] = useState(1);
  
  const navigate = useNavigate();

  //FUNCOES DO MODAL
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  //DADOS DO FORMULARIO PARA INSERIR CANAL
  const [numero, setNumero] = useState('');
  const [host, setHost] = useState('');
  const [idFila, setidFila] = useState('');
  const [apiKey, setapiKey] = useState('');

  //VARIAVEIS DE CONTROLE DE ALERTA
  const [showAlert, setShowAlert] = useState(false);

  //FUNCOES PARA CAPTURA DE DADOS DO FORMULARIO
  const handleChangeNumero = (event: any) => {
    event.preventDefault();
    setNumero(event.target.value);
  };

  const handleChangeHost = (event: any) => {
    event.preventDefault();
    setHost(event.target.value);
  };

  const handleChangeIdFila = (event: any) => {
    event.preventDefault();
    setidFila(event.target.value);
  };

  const handleChangeApiKey = (event: any) => {
    event.preventDefault();
    setapiKey(event.target.value);
  };

  //FUNCAO QUE EXECUTA O POST DE CANAIS
  async function handleSubmitForm(event: any) {
    event.preventDefault();
    let obj = {
      number: numero,
      host: host,
      queueId: idFila,
      apiKey: apiKey
    }

    const result = await axios(`${urlApi}app/channels/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
      },
      data: JSON.stringify(obj)
    }).then((response) =>{

      setAcao(true);
      Swal.fire(
        'Sucesso',
        'Forneça o codigo para o cliente ter acesso ao QrCode',
        'success'
      )
      setShow(false);
    })
      .catch((error) => {
        Swal.fire(
          'Erro',
          'Erro ao tentar cadastrar canal',
          'error'
        )
      });
      
  }


  //FUNCAO DE CAPTURA DOS DADOS A SEREM MOSTRADOS
  const getDados = async () => {
    const dados = await axios(`${urlApi}app/channels/get/${pagina}`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token')
      }
    }).then((response) => {
      setCanais(response.data);
      
    })
      .catch((error) => { 
        navigate('/');
       })

  }

  const verifyLogin = () => {
    if (localStorage.getItem('token') === "" || localStorage.getItem('token') === null || localStorage.getItem('token') === undefined) {

      return (
        navigate('/')
      )
    }
  }

  const setUpdateAcao = (value: boolean)=> {
    setAcao(value)
  }


  setInterval(() => {
    verifyLogin();

  }, 30000)

  useEffect(() => {
    getDados();
  }, [pagina]);


  useEffect(() => {
    console.log('Ocorreu uma acao');
    getDados();
    setUpdateAcao(false);
  }, [acao]);


  //FUNCAO PARA COPIAR O CODIGO PARA A AREA DE TRANSFERENCIA
  const handleCopy = (event: any) => {
    event.preventDefault();
    copy(event.target.value);
    console.log(event.target.value);
  };



  //FUNCAO PARA TRATAR OS CANAIS ATIVOS NA TABELA
  const mostrarAtivo = (dado: number) => {
    if (dado === 1) {
      return <p style={{ 'backgroundColor': 'green', color: 'white', borderRadius: '15px', textAlign: 'center' }}>Ativo</p>
    } else {
      return <p style={{ 'backgroundColor': 'red', color: 'white', borderRadius: '15px', textAlign: 'center' }}>Inativo</p>
    }
  }



  //FUNCAO PARA MANIPULAR ALERTAS
  const successAlert = () => {
    alert('Success');
    refreshPage();
  }

  //FUNCAO PARA RECARREGAR PAGINA
  function refreshPage() {
    window.location.reload();
  }


  //FUNCAO PARA RENOVAR E INATIVAR CLIENTE
  const hundleRenovarCliente = async (event: any) => {
    event.preventDefault();
    const result = await axios(`${urlApi}app/channels/renew`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
      },
      data: JSON.stringify({
        id: event.target.id
      })
    }).then((response) => {
      
      setAcao(true);
      if (response.status === 200) {
        setAcao(true);
        Swal.fire(
          'Canal Renovado com sucesso!',
          'Copie o codigo e envie para o cliente ter acesso ao QrCode',
          'success'
        )
      }


    })
      .catch((error) => {
        Swal.fire(
          'Erro',
          'Erro ao tentar renovar o canal',
          'error'
        )
      });

  }
  const hundleAtivarCanal = async (event: any) => {
    event.preventDefault();
    console.log(event.target.id);
    const result = await axios(`${urlApi}app/channel/activate`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
      },
      data: JSON.stringify({
        id: event.target.id
      })
    }).then((response) => {
      
      setAcao(true);
      if (response.status === 200) {
        setAcao(true);
        Swal.fire(
          'Canal Ativado com sucesso!',
          'Copie o codigo e envie para o cliente ter acesso ao QrCode',
          'success'
        )
      }


    })
      .catch((error) => {
        Swal.fire(
          'Erro',
          'Erro ao tentar inativar o canal',
          'error'
        )
      });

  }


  const hundleBloquearCliente = async (event: any) => {
    event.preventDefault();
    await axios(`${urlApi}app/channels/delete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
      },
      data: JSON.stringify({
        id: event.target.id
      })
    }).then((response) => {
      
      setAcao(true);
      if (response.status === 200) {
        setAcao(true);
        Swal.fire(
          'Canal Inativado com sucesso!',
          'Para ativar o canal novamente basta clicar no icone de ativar',
          'warning'
        )
      }


    })
      .catch((error) => {
        Swal.fire(
          'Erro',
          'Erro ao tentar inativar o canal',
          'error'
        )
      });
  }


  return (
    <>
        <BarraPrincipal />
        <br />
        <form><div className="divButton">
          <button type="button" className="btn btn-success buttonSave" onClick={handleShow}>Cadastrar Canal</button>
        </div></form>

        <br />
        

          <Table className='tableclient' responsive>
            <thead >

              <tr style={{ textAlign: 'center' }}>
                <th>Numero</th>
                <th>Host</th>
                <th>Id Canal</th>
                <th>ApiKey</th>
                <th>Expiracao</th>
                <th>Ativo</th>
                <th>Codigo</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody className='tableclient'>
              {canais.map((canais) => (

                <tr className='tableclient'>
                  <td >{canais['number']}</td>
                  <td >{canais['host']}</td>
                  <td >{canais['queueId']}</td>
                  <td >{canais['apiKey']}</td>
                  <td >{moment(canais['dataExp']).format('DD-MM-YYYY')}</td>
                  <td>{mostrarAtivo(canais['ativo'])}</td>
                  <td ><input className='labelCodigo' type="button" value={canais['public']} onClick={handleCopy}></input></td>
                  <td><Button className="stopCircle"  id={canais['id']} onClick={hundleBloquearCliente} >Inativar</Button > <Button className='renewCircle' id={canais['id']} onClick={hundleRenovarCliente}>Renovar </Button> <Button className='activateChannel' id={canais['id']} onClick={hundleAtivarCanal} > Ativar</Button></td>
                </tr>

              ))}
            </tbody>
          </Table>
          <Card style={{ borderStyle: 'none', alignItems: 'center' }}>
            <Pagination>
              <Pagination.Prev onClick={() => { if (pagina <= 1) { setPagina(1) } else { setPagina(pagina - 1); console.log('Pagina: ' + pagina) } }} />
              <Pagination.Next onClick={() => { setPagina(pagina + 1); console.log('Pagina' + pagina) }} />
            </Pagination></Card>

            <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Cadastro de Canais</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='formCadastro'>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Numero:</Form.Label>
                <Form.Control type="number" placeholder="Ex: 38991234567" onChange={handleChangeNumero} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Host</Form.Label>
                <Form.Control type="link" placeholder="Ex: https://ambientedetestes.atenderbem.com/" onChange={handleChangeHost} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Id Canal</Form.Label>
                <Form.Control type="link" placeholder="Digite o ID da fila" onChange={handleChangeIdFila} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Api Key</Form.Label>
                <Form.Control type="link" placeholder="Digite a ApiKey da fila" onChange={handleChangeApiKey} />
              </Form.Group>

            </Form>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="btn btn-secondary buttonClose" onClick={handleClose}>
              Fechar
            </Button>
            &nbsp;&nbsp;
            <button type="button" className="btn btn-success buttonSave" onClick={handleSubmitForm}>Cadastrar Canal</button>
          </Modal.Footer>
        </Modal>

    </>
  )


}

