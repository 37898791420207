import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login'
import Panel from './pages/Panel';
import Teste from './pages/Teste';
import VerificadorLogin from './utils/VerificadorLogin';
import Autenticacao from './pages/Autenticacao';

const Rotas = () => {
    return (
        <Router>
            <Routes >
                <Route path="/" element={<Login />} />
                <Route path="/activate" element={<Autenticacao />} />
                <Route path="/autentication" element={<Autenticacao />} />
                <Route element={<VerificadorLogin />}>
                    <Route path="/admin" element={<Panel />} />
                </Route>
                <Route path="/app/teste" element={<Teste />} />
            </Routes>
        </Router>

    )
}

export default Rotas;