import React from 'react';
import logo from './logo.svg';
import './App.css';
import Rotas from './routes';



function App() {
  return (
    <>
    <Rotas />
    </>
  );
}

export default App;
