export default class Api {
    public urlApi: string;
    
    
    constructor(){
        this.urlApi = "https://apimaturacao.kentro.top/";
    }

    public getUrlApi(): string{
        return this.urlApi;
    }
}

