import Navbar from 'react-bootstrap/Navbar';
import './Navbar.css';
import Logout from '../../utils/Logout';


const BarraPrincipal = () => {
  const handleLogout = async (event: any) => {
    console.log('Logout')
    localStorage.clear();
    <Logout redirecionamento='' />
  }

  return (
    <Navbar className="navextra" >

      <Navbar.Brand className="" href="/admin"><img className='iconnavbar' src="/imagens/logoblick.png" /></Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          <a className="text__principal" href="" onClick={handleLogout}>Logout</a>
        </Navbar.Text>
      </Navbar.Collapse>

    </Navbar>


  );
}

export default BarraPrincipal;