import { Navigate } from "react-router-dom";
import Panel from "../pages/Panel";

const VerificadorLogin = () =>{
    const token = localStorage.getItem('token');
    
    return (
        token ? <Panel /> : <Navigate to="/" />
    )
}

export default VerificadorLogin;