import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import { useState } from 'react';
import React from 'react';
import Swal from 'sweetalert2';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import VerificadorLogin from '../../utils/VerificadorLogin';
import {FiLogIn} from 'react-icons/fi';
import Api from '../../utils/Api';



const Login = ()=>{


    const novaApi = new Api();
    const navigate = useNavigate();

    const [usuario,setUsuario] = useState('');
    const [password,setPassword] = useState('');
    const [urlApi, setUrlApi] = useState(novaApi.getUrlApi());

    const handleChangeUsuario = (event: any) => {
        event.preventDefault();
        setUsuario(event.target.value);
    }

    const handleChangePassword = (event: any) => {
        event.preventDefault();
        setPassword(event.target.value);
    }

    const sendLogin = (event: any) => {
        const dados = {
            username: usuario,
            password: password
        }
        event.preventDefault();

        axios(`${urlApi}app/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(dados)
        }).then((response)=> {
            localStorage.setItem('token', response.data.token);
            navigate('/admin');
        }).catch((error) => {
            Swal.fire(
                'Erro ao realizar login',
                'Verifique seu usuario e senha e tente novamente!',
                'error'
            )
            //alert('Erro ao tentar entrar na plataforma')
        });
    }
    

    return (
        <div>
            <header className="App-header">
                <img src="/imagens/logoblick.png" className="loginLogo" alt="logo" />
                <br></br>
                <div className='card' style={{ border: "none", background: "#c8c8c8" }}>
                    <form className="formadd" onSubmit={sendLogin}>
                        <div className="mb-3">
                            <label className="form-label labelusername">Usuario</label>
                            <input type="text" name="username" className="form-control formadd" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={handleChangeUsuario} required />

                        </div>
                        <div className="mb-3">
                            <label className="form-label labelusername">Senha</label>
                            <input type="password" className="form-control formadd" id="exampleInputPassword1" onChange={handleChangePassword} required />
                        </div>
                        <div className="mb-3 form-check">
                        </div>
                        <button type="submit" className="btn btn-primary buttonadd"><FiLogIn /> Entrar</button>
                    </form>
                </div>
            </header>
        </div>
    )
}

export default Login;